<template>
  <el-main class="main">
    <div class="title">商标业务</div>
    <div class="sep" />
    <div class="content">
      <div
        class="row"
        v-for="info in infoList"
        :key="info.index"
        @click="handleClick(info.url)"
      >
        <span>{{ info.index }}）{{ info.title }}</span>
      </div>
    </div>
  </el-main>
</template>

<script>
import store from "@/store";
export default {
  name: "others",
  data() {
    return {
      infoList: [
        {
          index: 1,
          title: "商标业务缴费指南",
          url: "http://sbj.cnipa.gov.cn/sbj/sbsq/sqzn/201912/t20191227_611.html",
        },
        {
          index: 2,
          title: "网上缴费常见问题解答指南",
          url: "http://sbj.cnipa.gov.cn/sbj/sbsq/sqzn/202001/t20200117_608.html",
        },
        {
          index: 3,
          title: "商标注册申请常见问题指南",
          url: "http://sbj.cnipa.gov.cn/sbj/sbsq/sqzn/202211/t20221103_23079.html",
        },
        {
          index: 4,
          title: "申请注册商品商标或服务商标指南",
          url: "http://sbj.cnipa.gov.cn/sbj/sbsq/sqzn/201811/t20181107_614.html",
        },
        {
          index: 5,
          title: "商标注册流程简图",
          url: "http://sbj.cnipa.gov.cn/sbj/sbsq/sqzn/201404/t20140430_583.html",
        },
        {
          index: 6,
          title:
            "申请变更商标申请人注册人名义地址变更集体商标证明商标管理规则集体成员名单变更商标代理人文件接收人指南",
          url: "http://sbj.cnipa.gov.cn/sbj/sbsq/sqzn/201811/t20181114_602.html",
        },
        {
          index: 7,
          title: "申请转让注册商标注册申请指南",
          url: "http://sbj.cnipa.gov.cn/sbj/sbsq/sqzn/201811/t20181114_601.html",
        },
        {
          index: 8,
          title: "申请续展注册商标指南",
          url: "http://sbj.cnipa.gov.cn/sbj/sbsq/201811/t20181114_5936.html",
        },
        {
          index: 9,
          title:
            "申请注册商标使用许可备案、变更许可人/被许可人名称备案、商标使用许可提前终止备案、撤回商标使用许可备案指南",
          url: "http://sbj.cnipa.gov.cn/sbj/sbsq/201811/t20181114_5937.html",
        },
        {
          index: 10,
          title: "申请补发《商标注册证》指南",
          url: "http://sbj.cnipa.gov.cn/sbj/sbsq/201811/t20181115_5926.html",
        },
        {
          index: 11,
          title: "申请注册商标专用权质权的登记、变更、延期以及注销指南",
          url: "http://sbj.cnipa.gov.cn/sbj/sbsq/201811/t20181114_5954.html",
        },
        {
          index: 12,
          title: "申请补发变更转让续展证明指南",
          url: "http://sbj.cnipa.gov.cn/sbj/sbsq/201811/t20181115_5927.html",
        },
        {
          index: 13,
          title: "保护商标专用权",
          url: "http://sbj.cnipa.gov.cn/sbj/sbsq/sqzn/201404/t20140430_580.html",
        },
        {
          index: 14,
          title: "国家知识产权局关于发布《商标审查审理指南》的公告（第462号）",
          url: "https://www.cnipa.gov.cn/art/2021/11/22/art_74_171575.html",
        },
      ],
    };
  },
  beforeRouteEnter(to, from, next) {
    store.commit("business/SET_ACTIVEINDEX", { activeIndex: "2" });
    next();
  },
  methods: {
    handleClick(url) {
      window.open(url, "_blank");
    },
  },
};
</script>

<style lang="stylus" scoped>
.main {
  padding-left: 100px;
  padding-right: 100px;
  padding-bottom: 72px;

  .title {
    font-size: 30px;
    font-family: PingFang-SC-Heavy, PingFang-SC;
    font-weight: 800;
    color: #2C53B0;
    line-height: 42px;
    text-align: center;
  }

  .sep {
    height: 2px;
    background: #2C53B0;
    margin-top: 14px;
  }

  .content {
    padding-top: 12px;
    padding-bottom: 100px;

    .row {
      padding: 13px 12px 13px 12px;
      border-bottom: 1px solid #F3F5FB;

      &:hover {
        background-color: #F3F5FB;
        cursor: pointer;

        span {
          color: #2C53B0;
          font-weight: bold;
        }
      }


      span {
        font-size: 16px;
        font-family: PingFang-SC;
        font-weight: 400;
        color: #222222;
        line-height: 24px;
      }
    }
  }
}
</style>