var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-main", { staticClass: "main" }, [
    _c("div", { staticClass: "title" }, [_vm._v("商标业务")]),
    _c("div", { staticClass: "sep" }),
    _c(
      "div",
      { staticClass: "content" },
      _vm._l(_vm.infoList, function(info) {
        return _c(
          "div",
          {
            key: info.index,
            staticClass: "row",
            on: {
              click: function($event) {
                return _vm.handleClick(info.url)
              }
            }
          },
          [_c("span", [_vm._v(_vm._s(info.index) + "）" + _vm._s(info.title))])]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }